// Config values
$platform-name: "stlukeshospiceclone";

// Colours
$brand-lt-blue: #009ee3;
//$brand-primary-text-contrast-override: #FFF;
$brand-dk-blue: #005CA5;
$brand-primary: $brand-dk-blue;
$brand-secondary: $brand-lt-blue;
//$brand-secondary-text-contrast-override: null;

$donate-colour: #f2cf01;
$link-colour: $brand-dk-blue;

// Create greyscale
$black: #000;
$grey-dark: #292b2c;
$grey: #464a4c;
$grey-light: #636c72;
$grey-lighter: #ccc;
$grey-lightest: #f0f0f0;
$white: #fff;

// Layout
$container-max-width: 1230px;
$gap-width: 30px;
$site-bleed: 20px;

$border-radius: 0;
$border-colour: $grey-light;
$box-shadow: none;

// Breakpoints
$breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1024px,
  xl: 1200px
);

// Misc
$body-bg: $white;
$profile-pics-enabled: true;
$border-width: 1px;
$date-ordinals-enabled: true;

// Transitions
$transition-duration: 0.1s;
$transition-duration-slow: 0.5s;
$transition-curve: ease;

// Logo
$logo: "logo-stacked-Registration-mark.png";
$logo-retina: $logo;
$logo-width: 189px;
$logo-height: 107px;

$logo-mobile: $logo;
$logo-mobile-retina: $logo-mobile;
$logo-mobile-width: $logo-width * 0.7;
$logo-mobile-height: $logo-height * 0.7;

// Spacers
$spacer: 1rem;

// Typography
$font-family-base: 'Nunito', sans-serif !default;
$headings-font-family: $font-family-base !default;
$body-colour: $grey-dark;
$headings-colour: $body-colour;
$headings-text-transform: none;

$font-weight-normal: normal;
$font-weight-bold: bold;
$headings-font-weight: $font-weight-bold;
//$headings-font-family: 'Josefin Sans', sans-serif;
$headings-text-transform: none;
$font-weight-base: $font-weight-normal;
$line-height-base: 1.5;

$font-size-h1: 2.5em;
$font-size-h2: 2em;
$font-size-h3: 1.75em;
$font-size-h4: 1.5em;
$font-size-h5: 1.25em;
$font-size-h6: 1em;

$font-size-base: 1rem;
$font-size-large: 1.25em;
$font-size-small: 0.875em;

// Buttons
$btn-background-colour: $brand-primary;
$btn-text-colour: text-contrast($btn-background-colour);
$btn-border-radius: $border-radius;
$btn-border-colour: $border-colour;
$btn-border-width: 0;
$btn-padding-x: 1.75em;
$btn-padding-y: 0.875em;
$btn-font-size: $font-size-base;
$btn-font-family: $headings-font-family;
$btn-font-weight: bold;
$btn-text-transform: uppercase;
$btn-hover-style: darken;
$btn-transition-duration: $transition-duration;

$donate-btn-background-colour: $donate-colour;
$donate-btn-colour: text-contrast($donate-btn-background-colour);

// Card
$card-details-padding: $spacer * 2.25;
$card-heading-colour: $headings-colour;
$card-heading-font-size: $font-size-h4;
$card-heading-margin-bottom: $spacer;
$card-summary-font-size: $font-size-base;
$card-heading-text-transform: unset;
$card-details-background-colour: $white;
$card-footer-background-colour: $card-details-background-colour;
$card-min-width: 280px;

// Card hover
$card-hover-heading-colour: $brand-primary;
$card-hover-border-colour: $brand-primary;

// Global alert
$global-alert-background-colour: $brand-secondary;
$global-alert-colour: text-contrast($global-alert-background-colour);

// Menu admin
$menu-admin-enabled: false;
$menu-admin-absolute: true;
$menu-admin-background-colour: transparent;
$menu-admin-to-nav-breakpoint: map-get($breakpoints, lg);
$menu-admin-link-colour: $body-colour;

// Header
$header-background-colour: #fff;
$header-content-margin-top: $spacer * 1.55;
$header-content-margin-bottom: $spacer * 1.55;

// Social Icons
$social-icons-font-size: 1rem;
$social-icons-gap: 0;
$social-icons-background-colour: transparent;
$social-icons-colour: $body-colour;
$header-social-icons-margin-right: 20px;

// Header - Social Icons
$social-icons-header-colour: text-contrast($header-background-colour);
$social-icons-header-hover-colour: text-contrast($header-background-colour);
$social-icons-footer-hover-colour: $brand-secondary;

// Social icons
$header-social-icons-margin-right: 10px;

// Tagline
$tagline-enabled: false;
$tagline-font-family: $headings-font-family;
$tagline-font-weight: $font-weight-bold;
$tagline-font-size: $font-size-h4;
$tagline-colour: $headings-colour;
$tagline-breakpoint: map-get($breakpoints, lg);

// Main call to action
$cta-btn-background-colour: $brand-primary;
$cta-btn-gap: 10px;
$cta-btn-breakpoint: map-get($breakpoints, md);
$cta-donate-on-appeal: false;

// Search
$header-search-enabled: true;

// Navigation
$nav-normal-max-width: 100%;
$nav-normal-align-items: center;
$nav-top-level-item-font-size: $font-size-large;
$nav-background-colour: $white;
$nav-normal-margin-bottom: 0;

// Nav - Top Level
$nav-top-level-item-padding: 15px 20px;
$nav-top-level-item-font-size: $font-size-base;
$nav-top-level-item-colour: text-contrast($nav-background-colour);
$nav-top-level-item-hover-colour: $brand-primary;
$nav-top-level-item-hover-background-colour: transparent;
$nav-top-level-first-item-home-icon: false;

// Nav - Submenu
$nav-submenu-background-colour: $nav-background-colour;
$nav-submenu-box-shadow: 0 5px 10px rgba($black, 0.2);
$nav-submenu-item-colour: text-contrast($nav-background-colour);
$nav-submenu-item-hover-background-colour: rgba($black, 0.065);

// Burger button
$burger-btn-background-colour: $brand-primary;
$burger-btn-text-colour: text-contrast($brand-primary);
$burger-btn-icon-breakpoint: null;
$burger-btn-bar-breakpoint: null;

// Nav Mobile
$nav-normal-mobile-background-colour: $grey-lightest;

// Carousel
$carousel-max-width: 100%;
$carousel-contents-max-width: $container-max-width;
$carousel-image-overlay: linear-gradient(
  to right,
  #000 0%,
  rgba(0, 0, 0, 0) 68.47%
);
$carousel-details-position-y: center;
$carousel-details-background-colour: transparent;
$carousel-details-box-shadow: none;
$carousel-details-max-width: 550px;
$carousel-details-padding: $spacer * 2;
$carousel-heading-font-size: 3.25em;
$carousel-heading-colour: $white;
$carousel-summary-colour: $white;
$carousel-summary-enabled: true;

$carousel-breakpoint: map-get($breakpoints, md);
$carousel-controls-button-size: 40px;
$carousel-controls-position-y: bottom;
$carousel-controls-position-x: right;
$carousel-controls-margin-x: 0;
$carousel-controls-icon-colour: #fff;
$carousel-controls-button-gap: 1px;
$carousel-controls-icon-font-size: 1.8em;

$carousel-buttons-enabled: false;

$carousel-mobile-details-background-colour: $brand-primary;
$carousel-mobile-heading-colour: text-contrast(
  $carousel-mobile-details-background-colour
);
$carousel-mobile-summary-colour: text-contrast(
  $carousel-mobile-details-background-colour
);

$carousel-read-more-enabled: true;
$carousel-read-more-background-colour: darken(
  $carousel-mobile-details-background-colour,
  10%
);
$carousel-read-more-colour: text-contrast(darken($brand-primary, 10%));
$carousel-read-more-font-size: $font-size-small;

// Home intro
$home-intro-max-width: 100%;
$home-intro-background-colour: transparent;
$home-intro-content-max-width: $container-max-width;
$home-intro-content-align: center;
$home-intro-padding-y: $spacer * 3;
$home-intro-margin-top: $spacer * 2;
$home-intro-margin-bottom: 0;
$home-intro-text-align: center;
$home-intro-text-transform: $headings-text-transform;
$home-intro-font-family: $headings-font-family;
$home-intro-font-size: $font-size-h3;
$home-intro-font-weight: $headings-font-weight;
$home-intro-colour: $brand-lt-blue;

// Home features
$home-features-max-width: $container-max-width;
$home-features-background-colour: transparent;
$home-features-padding-top: $spacer * 3;
$home-features-padding-bottom: $spacer * 1.5;

// Impact stats
$impact-stats-margin-top: $spacer * 1.5;
$impact-stats-margin-bottom: $spacer * 2.5;
$impact-stats-padding-y: $spacer * 2.5;
$impact-stats-background-colour: transparent;
$impact-stats-colour: $body-colour;

// Impact stats - Heading
$impact-stats-heading-margin-bottom: -$spacer * 2;

// Impact stats - Individual stat
$impact-stat-padding: $spacer * 4 $spacer;

// Impact stats - Figure
$impact-stats-figure-colour: $brand-secondary;
$impact-stats-figure-font-size: 3em;

// Impact stats - Summary
$impact-stats-summary-margin-top: $spacer;

// Home feeds
$home-feeds-background-colour: transparent;
$home-feeds-padding-top: $spacer * 6;
$home-feeds-padding-bottom: $spacer * 6;

// Feeds title
$feeds-title-colour: $body-colour;

// Feed item
$feed-item-read-more-enabled: false;

// Event feed items
$feed-item-event-start-date-enabled: false;
$feed-item-event-location-enabled: false;

// Footer
$footer-boxes-count: 5;
$footer-prefab: 1;
$footer-background-colour: $brand-dk-blue;
$footer-link-colour: rgba(text-contrast($footer-background-colour), 0.75);
$footer-headings-font-size: $font-size-h4;

// Footer - Social Icons
$social-icons-footer-colour: text-contrast($footer-background-colour);
$social-icons-footer-hover-colour: text-contrast($footer-background-colour);

// Newsletter
$newsletter-heading-font-size: $font-size-h4;
$newsletter-background-colour: $footer-background-colour;
$newsletter-heading-text-align: left;
$newsletter-input-background-colour: rgba($white, 0.5);
$newsletter-button-background-colour: $brand-primary;
$newsletter-button-colour: text-contrast($newsletter-button-background-colour);

// Context container
$context-container-background-colour: $white;

// Post
$post-content-max-width: $container-max-width;
$context-container-padding: $spacer * 2;
$context-container-border-radius: $border-radius;

// Header text (container for breadcrumb and page title)
$header-text-margin-top: $spacer * 2;
$header-text-margin-bottom: $spacer * 4;

// Page title
$page-title-margin-top: $spacer * 3;
$page-title-colour: $headings-colour;
$page-title-text-align: left;
$page-title-font-size: $font-size-h1;

// Sidebar
$sidebar-adjacent-width: 280px;
$sidebar-adjacent-gap: $spacer * 6;
$sidebar-padding: 0;
$associated-item-border: false;

// Listed post settings
$listed-news-post-publish-date-enabled: false;
$listed-blog-post-publish-date-enabled: false;
$listed-blog-post-author-enabled: false;
$listed-appeal-totaliser-enabled: false;

// Blockquote
$blockquote-background-colour: $brand-secondary;
$blockquote-margin-y: $spacer;
$blockquote-text-align: left;
$blockquote-font-size: $font-size-h4;

// Top fundraisers
$top-fundraisers-enabled: false;

// Quick giving panel
$quick-giving-background-colour: $grey-lightest;
$quick-giving-donation-amount-padding: 0.5rem 1rem;
$quick-giving-donation-amount-background-colour: $white;
$quick-giving-donation-amount-selected-background-colour: $donate-colour;

// Home quick giving panel
$home-quick-giving-type: adjacent;
$home-quick-giving-padding-y: $spacer * 6;
$home-quick-giving-background-colour: $brand-lt-blue;
$home-quick-giving-donation-amount-background-colour: transparent;
$home-quick-giving-donation-amount-figure-colour: text-contrast(
  $home-quick-giving-background-colour
);
$home-quick-giving-donation-amount-border-width: 1px;
$home-quick-giving-donation-amount-border-colour: text-contrast(
  $home-quick-giving-background-colour
);
$home-quick-giving-donation-amount-selected-background-colour: rgba(
  text-contrast($home-quick-giving-background-colour),
  0.35
);
$home-quick-giving-donation-amount-selected-figure-colour: $grey-dark;
$home-quick-giving-donate-btn-background-colour: $donate-colour;
$home-quick-giving-donate-btn-colour: text-contrast($donate-colour);
$home-quick-giving-colour: text-contrast($home-quick-giving-background-colour);
$home-quick-giving-heading-colour: text-contrast(
  $home-quick-giving-background-colour
);

// Totalisers
$totaliser-layout: vertical;

// Comments
$comment-add-image-enabled: true;
$comment-add-video-enabled: true;

// Blog details
$blog-details-enabled: false;

// FAQs
$faq-content-max-width: 790px;
$faq-item-header-background-colour: $card-details-background-colour;
$faq-item-header-padding: $spacer * 2;
$faq-item-btn-background-colour: transparent;
$faq-item-btn-chevron-colour: $body-colour;
$faq-item-hover-btn-background-colour: transparent;
$faq-item-hover-btn-chevron-colour: $brand-primary;

// Product-price
$product-price-colour: $donate-colour;

// Keeping In Touch
$keeping-in-touch-enabled: true !default;
$keeping-in-touch-via-email-enabled: true !default;
$keeping-in-touch-via-telephone-enabled: true !default;
$keeping-in-touch-via-sms-enabled: false;
$keeping-in-touch-via-post-enabled: false;