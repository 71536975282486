// Nav border
nav.menuMain {
  border-style: solid;
  border-color: $border-colour;
  border-width: $border-width 0 $border-width 0;
}

// Basket in mainCallToAction
// ul.socialIcons .menuAdminBasket a {
//    color: rgba(text-contrast($header-background-colour), 0.5);
//    font-size: $font-size-small;
//    width: auto;
//    margin-right: 5px;
//    &:hover { text-decoration: underline; }
// }

// Carousel control icon
.carouselControlPrev:before {
  content: '\f104';
}
.carouselControlNext:before {
  content: '\f105';
}

// Carousel Read More - Mobile only
@media (min-width: 769px) {
  .carouselSlideReadMore {
    display: none;
  }
}

// Homepage Stats
.homeFeature .homeImpactWrapper table {
  border: $border-width solid $border-colour;
}
.homeFeature .homeImpactWrapper > h2 {
  display: inline-block;
  width: auto;
  margin-left: auto;
  margin-right: auto;
  padding: 0 $spacer * 2;
  background-color: $body-bg;
  z-index: 2;
}

// Homepage Stats - Mobile
@media (max-width: map-get($breakpoints, lg)) {
  .homefeaturecategory-homeboximpactstats {
    margin-top: 1.25rem;
    tr { padding: 2rem 1rem; }
  }
}


// Homefeeds - smaller screens
@media (max-width: map-get($breakpoints, lg)) {
  .feedItem [class*="DetailsWrapper"]:not(.publishDetailsWrapper),
  .feedItem:last-of-type [class*="DetailsWrapper"]:not(.publishDetailsWrapper) {
    padding: $spacer;
  }
  .feedItem:last-of-type,
  .feedItem:last-of-type:hover {
    box-shadow: 0 1px 0 #636c72;
  }
}

// Footer
.pageFooter {
  display: flex;
  justify-content: center;
  text-align: center;
}
.footerBox ul {
display:inline-flex;
}
.footerBox ul li {
padding: 20px;
}

.findAFundraiser, .associatedBox {
  border: $border-width solid $border-colour;
}

// Associated Items
.associatedBox, .associatedAppeal {
  padding-bottom: $spacer;
  > h2, > p {
    padding: 0 $spacer;
  }
  > h2 a{
    color: $body-colour;
  }
  a.button {
    display: block;
    margin: 5px $spacer;
  }
}

.associated:not(.associatedBox) > h2 {
  display: inline-block;
  padding: 1em;
  margin: 0;
  font-size: $font-size-small !important;
  text-transform: uppercase;
  background-color: $grey;
  color: $white;
}

.associatedListing {
  border: $border-width solid $border-colour;
  padding: $spacer;
   li {
    border: none !important;
  }
}


// Page Title
.headerText h1 {
  padding-bottom: .5em;
  border-bottom: 1px solid #ccc;
}


// FAQs
.listedFaq, .accordianHeader {
  outline: $border-width solid $border-colour;
}
.accordianHeader:hover { background-color: $faq-item-header-background-colour; }
.accordianHeader { margin-bottom: 0; }
.listedFaq { margin-bottom: 30px; }
.accordianBody.faqAnswer .contentBlock { padding-top: $spacer * 2; }
